import React from 'react'
// import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import SentBrand from 'assets/img/SENT-Token-Logo.png'
import SentBrandVertical from 'assets/img/SENT-Token-Logo-alt.png'
import SentBrandLight from 'assets/img/SENT-Token-Logo.png'
import SentBrandLightVertical from 'assets/img/SENT-Token-Logo-alt.png'
import { useTheme } from 'hooks/useTheme'

// const useStyles = makeStyles((theme) => ({
//   image: {
//     // marginRight: theme.spacing(1),
//     // marginLeft: theme.spacing(1),
//     // alignItems: 'center'
//   }
// }))

export const Brand = ({ 
  vertical=false, 
  size=64,
  dark=true // override for dark text, needed for topbar
}) => {
  // const classes = useStyles()
  const { colorMode } = useTheme()

  return (
    <img 
      src={colorMode === "dark" && dark
        ? vertical
          ? SentBrandLightVertical
          : SentBrandLight
        : vertical 
          ? SentBrandVertical 
          : SentBrand
      }
      style={vertical ? { width: `${size}px` } : { height: `${size}px` }}
      // className={classes.image}
      alt="sent-brand"
    />
  )
}
