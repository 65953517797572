import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { TopBar } from 'components/TopBar'
import { Footer } from 'components/Footer'
import { Home } from './Home'
import { Pools } from './Pools'
import { NoMatch } from './NoMatch'
import { Account } from './Account'
import { Legal } from './Legal'
import { Scroll } from 'components/Scroll'

const Routes = () => {
  return (
    // <Box display="flex" flexDirection="column" p={3} flexGrow={1}>
    <Router>
      <Scroll>
      <TopBar />

      <Switch>
        {/* Alphabetical order with / first and nothing last as catch all */}
        <Route exact path="/" component={Home} />
        <Route path="/account" component={Account} />
        <Route path="/legal" component={Legal} />
        <Route path="/pools" component={Pools} />
        
        <Route component={NoMatch} />
      </Switch>

      <Footer />
      </Scroll>
    </Router>
    // </Box>
  )
}

export default Routes
