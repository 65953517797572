import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import BarChartIcon from '@material-ui/icons/BarChart'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import TerrainIcon from '@material-ui/icons/Terrain';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew'; // External Links
// import StoreIcon from '@material-ui/icons/Store'; // OpenSea
import DescriptionIcon from '@material-ui/icons/Description';
import CachedIcon from '@material-ui/icons/Cached';

const RouteMap = {
  active: [
    {
      title: "Home",
      path: "/",
      icon: () => <HomeIcon />
    },
    {
      title: "Pools",
      path: "/pools",
      icon: () => <TerrainIcon />
    },
  ],
  external: [
    // {
    //   title: "Chart",
    //   path: "https://",
    // }
  ],
  footer: [
    {
      title: "Account",
      path: "/account"
    },
    {
      title: "Legal",
      path: "/legal"
    }
  ]
}

export default RouteMap