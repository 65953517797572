export const ARAGON_ENDPOINTS = {
  1: "https://mainnet.eth.aragon.network/",
  4: "https://rinkeby.eth.aragon.network/"
}

export const FORTMATIC_PUBLIC_KEYS = {
  1: "pk_live_DBFFB7B8669B8CC0",
  4: "pk_live_DBFFB7B8669B8CC0" // also kovan and ropsten
}

export const PORTIS_DAPP_ID = "43b2363c-5be6-418f-8d65-2ef7006a779e"