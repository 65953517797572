import Sent from 'eth'
import { useNetwork } from 'hooks/useNetwork'
import { createContext, useEffect, useState } from 'react'
import { useWallet } from 'use-wallet'

export const SentContext = createContext({
  sent: undefined
})

const SentProvider = ({ children }) => {
  const { account, ethereum } = useWallet()
  const { chainId } = useNetwork()
  const [sent, setSent] = useState()

  window.eth = ethereum
  
  useEffect(() => {
    if (ethereum) {
      const { selectedAddress } = ethereum

      const sentLib = new Sent(ethereum, chainId, {
        defaultAccount: selectedAddress,
        ethereumNodeTimeout: 10000,
      })
      setSent(sentLib)
      window.sent = sentLib
    }
  }, [account, ethereum, setSent])

  return (
    <SentContext.Provider value={{
      sent
    }}>
      {children}
    </SentContext.Provider>
  )
}

export default SentProvider