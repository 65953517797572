export const DISCORD_URL = "https://discord.com/channels/774678358998909008/854697363310510091"
export const GITHUB_URL = ""
export const MEDIUM_URL = ""
export const TELEGRAM_URL = "https://t.me/ttcsenttoken"
export const TELEGRAM_ANN_URL = ""
export const TWITTER_URL = "https://twitter.com/senttoken"

export const WHITEPAPER_URL = "https://assets.altitude.finance/static/files/Altitude_Finance_Whitepaper.pdf"

export const LGE_BALANCE_URL = ""
export const OPEN_SEA_STOREFRONT_URL = ""