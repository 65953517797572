import sentLogo from 'assets/img/SENT-Token-Logo.png'
import dashLogo from 'assets/img/dash-dash-logo.png'
import relayLogo from 'assets/img/relay-relay-logo.png'
import chartLogo from 'assets/img/ChartExNewLogo2.png'
import Addresses from './Addresses'

const SlopesMap = (chainId) => ({
  pools: [
    {
      pid: 0,
      name: "SENT",
      symbol: "SENT",
      logo: sentLogo,
      lpStaked: false,
      decimals: 18,
      address: "",
      lpAddress: ""
    },
    {
      pid: 1,
      name: "DASH",
      symbol: "DASH",
      logo: dashLogo,
      lpStaked: false,
      decimals: 18,
      address: "",
      lpAddress: ""
    },
    {
      pid: 2,
      name: "RELAY",
      symbol: "RELAY",
      logo: relayLogo,
      lpStaked: false,
      decimals: 18,
      address: "",
      lpAddress: ""
      
    },
    {
      pid: 3,
      name: "CHART",
      symbol: "CHART",
      logo: chartLogo,
      lpStaked: false,
      decimals: 18,
      address: "",
      lpAddress: ""
    },
  ]
})

export default SlopesMap