const Addresses = {
  AddressRegistry: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  Avalanche: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  AvalancheOld: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  LGE: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  Lodge: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  Loyalty: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  SENT: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  SENTLP: {
    1: "0xe266486afe9763c93530893e90264144c3a4023d",
  },
  Slopes: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  SlopesOld: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  }, 
  SnowPatrol: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  },
  Vault: {
    1: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    4: "0x97abee33cd075c58bfdd174e0885e08e8f03556f",
    8545: "0x97abee33cd075c58bfdd174e0885e08e8f03556f"
  }
}

export default Addresses