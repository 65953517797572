import SENT from '../abi/SENT.json'
import Slopes from '../abi/Slopes.json'
import SlopesOld from '../abi/SlopesOld.json'
import Addresses from 'constants/Addresses'

class Contracts {
  constructor(provider, web3, chainId, options) {
    this.web3 = web3

    // Init our web3.eth.Contract objects here
    this.SENT = new this.web3.eth.Contract(SENT)
    this.Slopes = new this.web3.eth.Contract(Slopes)
    this.SlopesOld = new this.web3.eth.Contract(SlopesOld)

    this.setProvider(provider, chainId)
    this.setDefaultAccount(this.web3.eth.defaultAccount)
  }

  // set contract provider and address
  setProvider(provider, chainId) {
    const setProvider = (contract, address) => {
      contract.setProvider(provider)
      if (address) {
        contract.options.address = address
      } else {
        console.error('Contract address not found in network', chainId)
      } 
    }

    setProvider(this.SENT, Addresses.SENT[chainId])
    setProvider(this.Slopes, Addresses.Slopes[chainId])
    setProvider(this.SlopesOld, Addresses.SlopesOld[chainId])
  }

  // set default sending account
  setDefaultAccount(account) {
    this.SENT.options.from = account
    this.Slopes.options.from = account
    this.SlopesOld.options.from = account
  }
}

export default Contracts